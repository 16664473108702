<template>
  <picture :class="props.pictureClass">
    <source v-if="props.webp" :srcset="webpFilename" type="image/webp" />
    <img :loading="props.disableLazy ? undefined : 'lazy'" :src="props.src" :alt="props.alt" :title="props.title" :class="props.class" />
  </picture>
</template>
<script setup>

const props = defineProps({
  src: String,
  alt: String,
  title: String,
  class: String,
  pictureClass: String,
  webp: Boolean,
  disableLazy: Boolean
});

const webpFilename = computed(() => props.src?.replace(/(\.jpg|\.jpeg|\.png)/, '.webp'));

</script>